import React from 'react'
import {StaticImage} from "gatsby-plugin-image";


const Recommendations = () => (
    <div className="mx-auto max-w-screen-xl mt-16">
        <div className="text-lg max-w-prose mx-auto mb-2 pb-4"><p
            className="text-base text-center leading-6 text-indigo-600 font-semibold tracking-wide uppercase">WHAT
            DO MY MENTORS SAY</p> <h1
            className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-500 sm:text-4xl sm:leading-10">
            <a rel="noreferrer" target='_blank' className="hover:text-hot-pink" href="https://linkedin.com/in/pratikdaigavane">Recommendations</a>


        </h1>
        </div>
        <div data-aos="fade-up"
             className="lg:grid lg:grid-cols-1 lg:gap-8 mb-4 lg:items-start px-4 aos-init aos-animate">
            <div className="relative text-base max-w-prose mx-auto lg:max-w-none">
                <blockquote className="relative bg-gray-900 rounded-lg shadow-lg">
                    <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
                        <div className="grid grid-cols-4 lg:grid-cols-8">
                            <StaticImage
                                src="../images/imocha.png"
                                alt="imocha"
                                placeholder="none"
                            />
                        </div>
                        <div className="relative text-lg text-gray-300 leading-7 font-medium mt-8">
                            <svg fill="currentColor" viewBox="0 0 32 32"
                                 className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-800">
                                <path
                                    d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path>
                            </svg>
                            <p className="relative">
                                Pratik has been working with iMocha as an Intern for more than an year. Its
                                impressive to see a 2nd year and now 3rd year Engineering student showing a
                                professional attitude better than long tenured professionals. Pratik has been an
                                asset to the team with his tremendous technical curiosity, learning aptitude and
                                the
                                keenness to implement the learnings.
                            </p>
                            <br/>
                            <p className="relative">
                                He helped with researching, designing and implementing proof of concepts for our
                                iMocha compilers and simulators for several languages using Python which later
                                got incorporated into Production.
                            </p>
                            <br/>
                            <p className="relative">
                                Engineering students like Pratik help to keep the reputation of Technical
                                Education in India high. He would definitely be an asset to any organization he
                                will join. I wish him good luck in his career.

                            </p>
                        </div>
                    </div>
                    <a href="https://www.linkedin.com/in/madanvishal/" target="_blank" rel="noreferrer"><cite
                        className="flex items-center sm:items-start bg-gray-700 rounded-b-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
                        <div
                            className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 mr-4 sm:-mt-14 sm:mr-6">
                            <StaticImage
                                src="../images/vishal.png"
                                alt="Vishal Madan"
                                className="w-12 h-12 sm:w-20 sm:h-20"
                                imgClassName="rounded-full border-2 border-hot-pink"
                                placeholder="none"
                            />

                            {/*<img src={vishal} alt="Varun"*/}
                            {/*     className="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-indigo-300"/>*/}
                        </div>
                        <span className="text-gray-400 font-semibold leading-6"><strong
                            className="text-gray-200 font-semibold">Vishal Madan</strong> <br
                            className="sm:hidden"/>
                      Head of Engineering at imocha
                    </span></cite></a></blockquote>
            </div>
        </div>
    </div>
)

export default Recommendations

