import React from 'react'
import ProjectComponent from "../components/Project.component";
import {graphql, useStaticQuery} from "gatsby";


const Projects = () => {
    const data = useStaticQuery(graphql`query GetProjectsQuery {
        allProjectsJson {
            nodes {
                description
                id
                name
                repo
                details
                architecture {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
        }
    }
    `)


    return (
        <div className="mx-auto max-w-screen-xl mt-16 aos-init aos-animate" id="projects">
            <div className="text-lg max-w-prose mx-auto">
                <p className="text-base text-center leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                    HAVE A LOOK
                </p>
                <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-500 sm:text-4xl sm:leading-10">
                    <a rel="noreferrer" target='_blank' className="hover:text-hot-pink" href="https://github.com/pratikdaigavane">Projects</a>
                </h1>
            </div>
            <div className="mt-12 px-4 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none" >
                {
                    data.allProjectsJson.nodes.map(item => <ProjectComponent data={item} key={item.name}/>)
                }
            </div>
        </div>
    )
}


export default Projects